const navToggler = document.querySelector("[data-nav-toggler]");
const nav = document.querySelector("[data-nav]");

navToggler.addEventListener("click", () => {
  navToggler.classList.toggle("open");
  nav.classList.toggle("open");
  document.documentElement.classList.toggle("no-scroll");
});

const navItems = document.querySelectorAll("[data-nav-item]");

[...navItems].map((item) => {
  item.addEventListener("click", () => {
    document.documentElement.classList.remove("no-scroll");
    navToggler.classList.remove("open");
    nav.classList.remove("open");
  });
});
